import appConfig from "@/config";
import {getCsrfToken, getStorage} from "@/utility/helper";
import {setLogin} from "@/utility/authHelpers";

// @ts-ignore
const CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

function handleCredentialResponse(response: { credential: any; }) {
  fetch(`${appConfig.apiEndPoint}/auth-google-callback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
    body: JSON.stringify({
      credential: response.credential
    })
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  }).then(data => {
    if (data.status === true) {
      google.accounts.id.disableAutoSelect();
      setLogin(data.auth_token, data.email);
    } else {
      console.error('Error in server-side authentication');
    }
  }).catch(error => console.error('An error occurred during your request:', error));
}

export const initOneTabSignIn = () => {
  const auth_token = getStorage('auth_token')
  google.accounts.id.initialize({
    client_id: CLIENT_ID,
    callback: handleCredentialResponse,
    prompt_parent_id: 'google-prompt',
    cancel_on_tap_outside: false,
    ux_mode: 'popup',
    context: 'signin',
    login_uri: `${appConfig.domain}`,
    itp_support: false,// @ts-ignore
    use_fedcm_for_prompt: true,
    auto_select: !auth_token  // Only auto select an account if there's no auth_token
  });

  if (!auth_token) {
    google.accounts.id.prompt(); // Display the One Tap dialog
  }
}

export const signOutButton = async () => {
  const res = await fetch(`${appConfig.apiEndPoint}/sign-out`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    }
  })
  const data = await res.json()
  if (data?.success) {
    localStorage.removeItem('auth_token');
    window.location.href = data?.data?.redirect ?? `${appConfig.domain}`
  }
}
